// This file is part of Materials Cloud Archive
// Copyright (C) 2023 Materials Cloud Team.

import React, { Component } from "react";

import { Input, Array, SelectField, GroupField } from "react-invenio-forms";
import { Grid, Form, Button, Icon } from "semantic-ui-react";


export class MCsections extends Component {
  render() {
    const {
      fieldPath, // injected by the custom field loader via the `field` config property
      mc_link,
      mc_description,
      mc_section,
      icon,
      addButtonLabel,
      description,
      label,
    } = this.props;
    return (
      <Array
        fieldPath={fieldPath}
        label={label}
        icon={icon}
        addButtonLabel={addButtonLabel}
        defaultNewValue=""
        description={description}
      >
        {({ arrayHelpers, indexPath }) => {
          const fieldPathPrefix = `${fieldPath}.${indexPath}`;
          return (
            <>
            <GroupField optimized>
              <Grid.Column style={{ marginBottom: "1.75rem" }}>
                <SelectField
                fieldPath={`${fieldPathPrefix}.mc_section`}
                label={mc_section.label}
                placeholder={mc_section.placeholder}
                description={mc_section.description}
                options={mc_section.options}
                optimized
                required
                />
              </Grid.Column>
              <Grid.Column>
                <Input
                  fieldPath={`${fieldPathPrefix}.mc_link`}
                  label={mc_link.label}
                  placeholder={mc_link.placeholder}
                  description={mc_link.description}
                  optimized
                  required
                ></Input>
              </Grid.Column>
              <Grid.Column>
                <Input
                  fieldPath={`${fieldPathPrefix}.mc_description`}
                  label={mc_description.label}
                  placeholder={mc_description.placeholder}
                  description={mc_description.description}
                  optimized
                  required
                ></Input>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <Button
                    aria-label={"Remove field"}
                    className="close-btn"
                    icon
                    onClick={() => arrayHelpers.remove(indexPath)}
                    type="button"
                  >
                    <Icon name="close" />
                  </Button>
                </Form.Field>
              </Grid.Column>
            </GroupField>
            </>
          );
        }}
      </Array>
    );
  }
}
